export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const INVESTOR_REGISTER_SUCCESS = "INVESTOR_REGISTER_SUCCESS";
export const INVESTOR_REGISTER_FAIL = "INVESTOR_REGISTER_FAIL";
export const INVESTOR_LOGIN_SUCCESS = "INVESTOR_LOGIN_SUCCESS";
export const INVESTOR_LOGIN_FAIL = "INVESTOR_LOGIN_FAIL";
export const INVESTOR_UPDATE_PROFILE_SUCCESS = "INVESTOR_UPDATE_PROFILE_SUCCESS";
export const INVESTOR_UPDATE_PROFILE_FAIL = "INVESTOR_UPDATE_PROFILE_FAIL";

export const STARTUP_REGISTER_SUCCESS = "STARTUP_REGISTER_SUCCESS";
export const STARTUP_REGISTER_FAIL = "STARTUP_REGISTER_FAIL";
export const STARTUP_LOGIN_SUCCESS = "STARTUP_LOGIN_SUCCESS";
export const STARTUP_LOGIN_FAIL = "STARTUP_LOGIN_FAIL";
export const STARTUP_UPDATE_PROFILE_SUCCESS = "STARTUP_UPDATE_PROFILE_SUCCESS";
export const STARTUP_UPDATE_PROFILE_FAIL = "STARTUP_UPDATE_PROFILE_FAIL";