export const Data = [
    {
      id: 1,
      month: "january",
      portfolioValue: 0,
    },
    {
      id: 2,
      month: "february",
      portfolioValue: 0,
    },
    { 
      id: 3,
      month: "march",
      portfolioValue: 0,
    },
    {
      id: 4,
      month: "april",
      portfolioValue: 0,
    },
    {
      id: 5,
      month: "may",
      portfolioValue: 0,
    },
    {
      id: 6,
      month: "june",
      portfolioValue: 0,
    },
    {
      id: 7,
      month: "july",
      portfolioValue: 0,
    },
    {
      id: 8,
      month: "august",
      portfolioValue: 0,
    },
    {
      id: 9,
      month: "september",
      portfolioValue: 0,
    },
    {
      id: 10,
      month: "october",
      portfolioValue: 0,
    },
    {
      id: 11,
      month: "november",
      portfolioValue: 0,
    },
    {
      id: 12,
      month: "december",
      portfolioValue: 0,
    }
    
  ];