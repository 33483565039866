import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";

const UserDashboard = () => {
  
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>this is user dashboard</h3>
      </header>
    </div>
  );
};

export default UserDashboard;
